import styled from 'styled-components';
import buttonColor from '../../styles/contentful/buttonColor';

const ButtonLinkStyles = Comp => styled(Comp)`
  display: flex;
  color: inherit;
  text-decoration: none;
  padding: ${props =>
    props.size === 'large'
      ? props.theme.padding.button.large
      : props.theme.padding.button.small};
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  font-size: ${props =>
    props.size === 'large'
      ? props.theme.type.button.large
      : props.theme.type.button.small};
  line-height: ${props =>
    props.size === 'large'
      ? props.theme.lineHeights.button.large
      : props.theme.lineHeights.button.small};
  color: ${props =>
    props.outline
      ? props.appearance === 'dark'
        ? props.theme.colors.claytonBlue
        : props.theme.colors.white
      : props.appearance === 'dark'
        ? props.theme.colors.white
        : props.theme.colors.claytonBlue};
  border: solid 1px
    ${props =>
      props.appearance === 'dark'
        ? props.theme.colors.claytonBlue
        : props.theme.colors.white};
  background: ${props =>
    props.outline
      ? 'transparent'
      : props.appearance === 'dark'
        ? props.theme.colors.claytonBlue
        : props.theme.colors.white};

  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      color: ${props =>
        props.outline
          ? (props.hoverAppearance && props.hoverAppearance === 'dark') ||
            props.appearance === 'dark'
            ? props.theme.colors.white
            : props.theme.colors.claytonBlue
          : (props.hoverAppearance && props.hoverAppearance === 'dark') ||
              props.appearance === 'dark'
            ? props.theme.colors.claytonBlue
            : props.theme.colors.white};
      border: solid 1px
        ${props =>
          (props.hoverAppearance && props.hoverAppearance === 'dark') ||
          props.appearance === 'dark'
            ? props.theme.colors.claytonBlue
            : props.theme.colors.white};
      background: ${props =>
        props.outline
          ? (props.hoverAppearance && props.hoverAppearance === 'dark') ||
            props.appearance === 'dark'
            ? props.theme.colors.claytonBlue
            : props.theme.colors.white
          : 'transparent'};
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: initial;
  }

  ${props =>
    props.contentfulColors
      ? buttonColor(props.contentfulColors)[props.inverse ? 'inverse' : 'css']
      : ''}
`;

export default ButtonLinkStyles;
