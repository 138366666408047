import styled from 'styled-components';
import { breakpoints } from '../../styles/variables';

const AlertStyles = styled.div`
  color: white;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: ${props => props.theme.colors[props.background]};
  transition: all 0.4s ease-in-out;

  .alert-modal-header {
    color: ${props => props.theme.colors.bedtime};
    padding: 1rem !important;
    
  }

  .alert-modal-content {
    padding: 1rem;
  }

  .inner {
    position: relative;
    max-width: ${props => props.theme.maxWidths.alerts};
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .text {
    padding: 0.5rem ${props => props.theme.padding.inner} 0.5rem;
    width: 100%;
    max-width: ${props => props.theme.maxWidths.containerSmall};
    margin-right: 2rem;
    box-sizing: border-box;

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      text-align: center;
      margin: 0 auto;
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.container};
    }

    p {
      margin: 0;
      font-size: ${props => props.theme.type.paragraph.xs};
      line-height: ${props => props.theme.lineHeights.paragraph.xs};
      letter-spacing: ${props => props.theme.letterSpacing.paragraph};
      /* display: inline-block; */

      @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        font-size: ${props => props.theme.type.caption};
        line-height: ${props => props.theme.lineHeights.caption.tight};
        letter-spacing: ${props => props.theme.letterSpacing.caption};
      }
    }

    a {
      color: currentColor;
    }

    .cta-btn-link {
      border: none;
      padding: none;
      margin: none;
      display: inline;
      background: none;
      &:hover {
        cursor: pointer;
      }
    }

    .cta {
      font-size: ${props => props.theme.type.paragraph.md};
      font-weight: 400;
      color: white;
      text-decoration: underline;
    }
  }

  .close-button {
    border: none;
    padding: 0;
    outline: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    padding: none;
    width: 1.5rem;
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0.5rem;
    border-radius: 3px;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      right: 0;
    }

    &:active,
    &:focus {
      border: solid 1px white;
    }

    svg {
      width: 100%;
      position: absolute;
      top: 0.5rem;
      left: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    .text {
      .cta {
        margin: 0.25rem 0;
      }
    }
  }
`;

export default AlertStyles;
