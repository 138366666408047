import { useEffect, useState } from 'react';

const win = typeof window === 'undefined' ? null : window;

// Simple throttle function
const useThrottle = (callback, delay) => {
  const [lastRan, setLastRan] = useState(0);
  const [value, setValue] = useState(0);

  const throttledCallback = () => {
    const now = Date.now();
    if (now - lastRan >= delay) {
      setLastRan(now);
      setValue(callback());
    }
  };

  return [value, throttledCallback];
};

const getScrollY = () =>
  win.scrollY !== void 0
    ? win.scrollY
    : win.pageYOffset === void 0
      ? 0
      : win.pageYOffset;

const useWindowScroll = (fps = 30) => {
  const [scrollY, setScrollY] = useThrottle(getScrollY, 1000 / fps);

  useEffect(() => {
    const handleScroll = () => setScrollY(getScrollY());

    if (win) {
      win.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (win) {
        win.removeEventListener('scroll', handleScroll);
      }
    };
  }, [setScrollY]);

  return scrollY;
};

export default useWindowScroll;
