import React, { useEffect, useState, useMemo } from 'react';
import FooterStyles from './Footer.styled';
import Container from '../Shared/Container';
import { Link, useHistory, useLocation } from 'react-router-dom';
import EqualHousingIcon from '../Shared/EqualHousingIcon';
import ButtonLink from '../Shared/ButtonLink';
import Picture from '../Shared/Picture';
import useGTM from '../../hooks/useGTM';
import ArrowIcon from '../Shared/ArrowIcon';
import usePageIdentifier from '../../hooks/usePageIdentifier';
import createGTMClass from '../../util/createGTMClass';

const footerItems = {
  hideNavigation: [
    '/applynow/welcome/',
    '/applynow/welcome',
    '/applynow/welcome2/',
    '/applynow/welcome2',
    '/home-upgrade-loan/',
    '/home-upgrade-loan',
    '/contractor-enroll/',
    '/contractor-enroll',
    '/contractor-enroll/thank-you/',
    '/contractor-enroll/thank-you',
    '/contractor-enroll/contact-us/',
    '/contractor-enroll/contact-us',
    '/tva-energyright-loan/',
    '/tva-energyright-loan',
    '/discontinued-home-upgrade-loan',
    '/discontinued-home-upgrade-loan/',
    '/discontinued-tva-energyright-loan/',
    '/discontinued-tva-energyright-loan',
  ],
  showBackLink: [
    '/home-upgrade-loan/',
    '/home-upgrade-loan',
    '/contractor-enroll/',
    '/contractor-enroll',
    '/contractor-enroll/thank-you/',
    '/contractor-enroll/thank-you',
    '/contractor-enroll/contact-us/',
    '/contractor-enroll/contact-us',
    '/tva-energyright-loan/',
    '/tva-energyright-loan',
    '/discontinued-home-upgrade-loan',
    '/discontinued-home-upgrade-loan/',
    '/discontinued-tva-energyright-loan/',
    '/discontinued-tva-energyright-loan',
  ],
  hideAdditionalNavItems: [],
};

function Footer(props) {
  const [triggerGTMEvent] = useGTM();
  const {
    isHugContractorPage,
    isPPCPage,
    isPromoPage,
    isBusCardPage,
    isHugDiscontinuedPage,
    isTvaDiscontinuedPage,
  } = usePageIdentifier();
  const history = useHistory();
  const location = useLocation();
  const [hideNavigation, setHideNavigation] = useState(
    footerItems.hideNavigation.includes(location.pathname) ||
      location.pathname?.includes('/pages/')
  );
  const [showBack, setShowBack] = useState(
    footerItems.showBackLink.includes(location.pathname) ||
      location.pathname?.includes('/pages/')
  );
  const [hideAdditionalNavItems, setHideAdditionalNavItems] = useState(
    footerItems.hideAdditionalNavItems.includes(location.pathname) ||
      location.pathname?.includes('/promo/')
  );

  useEffect(() => {
    setHideNavigation(
      footerItems.hideNavigation.includes(window.location.pathname) ||
        location.pathname?.includes('/pages/') ||
        location.pathname?.includes('/promo/') ||
        location.pathname?.includes('/team/')
    );

    setShowBack(
      footerItems.showBackLink.includes(location.pathname) ||
        location.pathname?.includes('/pages/') ||
        location.pathname?.includes('/promo/') ||
        location.pathname?.includes('/team/')
    );

    setHideAdditionalNavItems(
      footerItems.hideAdditionalNavItems?.includes(location.pathname) ||
        location.pathname?.includes('/promo/') ||
        location.pathname?.includes('/discontinued-home-upgrade-loan') ||
        location.pathname?.includes('/discontinued-tva-energyright-loan')
    );
  }, [location.pathname]);

  const isEnergyRightPage = useMemo(() => {
    const possibleUrls = ['/tva-energyright-loan/', '/tva-energyright-loan'];

    return possibleUrls.includes(location.pathname);
  }, [location.pathname]);

  const handleBackToVMFClick = () => {
    history.push('/');
    triggerGTMEvent({
      name: 'vmf.cta_click',
      location: isHugContractorPage ? 'B2B Footer' : 'B2C Footer',
      category: 'loan_education',
      action: isHugContractorPage ? 'text' : 'footer',
      label: 'vmf.com_text',
    })();
  };

  return (
    <>
      <FooterStyles
        hideNavigation={hideNavigation}
        isEnergyRightPage={isEnergyRightPage}
        isPromoPage={isPromoPage}
        isBusCardPage={isBusCardPage}
        isHugDiscontinuedPage={isHugDiscontinuedPage}
        isTvaDiscontinuedPage={isTvaDiscontinuedPage}
      >
        <Container>
          <div className='footer-row'>
            <div className='footer-col'>
              <ul className='footer-list'>
                <li className='footer-list-item'>
                  <Link
                    to='/home-loans/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                  >
                    Mortgages & Loans
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/portfolio-home-loan/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    New Manufactured Home Loan
                  </Link>
                </li>
                {/* <li className="footer-list-item">
                <Link to="/fha-mortgage/" className="sub-link">
                  FHA mortgage
                </Link>
              </li> */}
                <li className='footer-list-item'>
                  <Link
                    to='/land-home-mortgage/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Land & home mortgage
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/biweekly-mortgage/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Biweekly Payment Loan
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/fresh-start-home-loan/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Less Than Perfect Credit
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/community-lending/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Community lending
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/direct-lending/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Used Home Loan
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/payment-options/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Payment options
                  </Link>
                </li>
              </ul>
            </div>
            <div className='footer-col'>
              <ul className='footer-list'>
                <li className='footer-list-item extra-bottom'>
                  <Link
                    to='/home-buying/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                  >
                    Home Buying
                  </Link>
                </li>
                <li className='footer-list-item extra-bottom'>
                  <Link
                    to='/home-owning/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                  >
                    Home Owning
                  </Link>
                </li>
                <li className='footer-list-item extra-bottom'>
                  <Link
                    to='/about/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                  >
                    About Vanderbilt Mortgage
                  </Link>
                </li>
                <li className='footer-list-item extra-bottom'>
                  <a
                    href='https://careers.claytonhomes.com/our-businesses/vanderbilt-mortgage'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                    onClick={triggerGTMEvent({
                      name: 'vmf.cta_click',
                      location: 'All Pages',
                      category: 'job_application',
                      action: 'footer',
                      label: 'careers',
                    })}
                  >
                    Careers
                  </a>
                </li>
                <li className='footer-list-item extra-bottom'>
                  <Link
                    to='/newsroom/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                  >
                    Newsroom
                  </Link>
                </li>
              </ul>
            </div>
            <div className='footer-col'>
              <ul className='footer-list'>
                <li className='footer-list-item'>
                  <Link
                    to='/calculators/mortgage-calculator/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                  >
                    Calculators
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/calculators/mortgage-calculator/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Mortgage calculator
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/calculators/early-payoff-calculator/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Early payoff calculator
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/calculators/amortization-calculator/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Amortization calculator
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/calculators/extra-monthly-principal/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Extra monthly principal
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/calculators/biweekly-extra-principal/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Biweekly extra principal
                  </Link>
                </li>
              </ul>
            </div>
            <div className='footer-col'>
              <ul className='footer-list'>
                <li className='footer-list-item'>
                  <Link
                    to='/resources/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                  >
                    Resources
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/resources/blog/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Tips & Tools
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/resources/documents-forms/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    Documents & Forms
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <Link
                    to='/resources/faqs/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'sub-link',
                    ])}
                  >
                    FAQs
                  </Link>
                </li>
              </ul>
            </div>
            <div className='footer-col'>
              <ul className='footer-list'>
                <li className='footer-list-item extra-bottom'>
                  <Link
                    to='/contact-us/'
                    className={createGTMClass('foot', 'footer', 'text', [
                      'top-link',
                    ])}
                  >
                    Contact
                  </Link>
                </li>
                <li className='footer-list-item'>
                  <ButtonLink
                    to={
                      location.pathname.includes('/direct-lending')
                        ? `/applynow/welcome?ReferringTM=null&skip=${encodeURIComponent('/preowned-q1')}`
                        : '/applynow/welcome/'
                    }
                    newTab={true}
                    isExternal={true}
                    outline={true}
                    appearance='light'
                    className={createGTMClass('apply', 'footer', 'button')}
                    onClick={triggerGTMEvent({
                      name: 'vmf.cta_click',
                      location: 'All Pages',
                      category: 'loan_application',
                      action: 'footer',
                      label: 'apply',
                    })}
                  >
                    Apply Today
                  </ButtonLink>
                </li>
              </ul>
            </div>
          </div>
          <hr className='divider' />

          {isEnergyRightPage && <hr className='divider' />}

          {showBack && (
            <section className='back_link'>
              <button
                onClick={handleBackToVMFClick}
                className={createGTMClass('vmf', 'footer', 'text')}
              >
                {!isPromoPage && <ArrowIcon />}
                {!isPromoPage && ' '}
                {isPPCPage ? 'Go to VMF.com' : 'Back to VMF.com'}
              </button>
            </section>
          )}
          <div className='legal-footer'>
            <div className='legal-footer-text'>
              <ul className='legal-footer-list nmls'>
                <li className='legal-footer-list-item'>
                  <a
                    href='https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1561'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    NMLS #1561 - NMLS Consumer Access
                  </a>
                </li>
              </ul>
              <ul className='legal-footer-list'>
                <li className='legal-footer-list-item privacy-legal-osano'>
                  <a
                    href='https://www.claytonhomes.com/privacy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy
                  </a>
                </li>
                <li className='legal-footer-list-item privacy-legal-osano'>
                  <Link to='/legal-privacy/'>Legal</Link>
                </li>
                <li className='legal-footer-list-item privacy-legal-osano'>
                  <button
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      window?.Osano?.cm?.showDrawer(
                        'osano-cm-dom-info-dialog-open'
                      )
                    }
                    tabIndex={0}
                  >
                    Do Not Sell or Share My Personal Information
                  </button>
                </li>
              </ul>
              <hr className='legal-divider' />
              <div className='legal-footer-copyright'>
                <p className='type-caption color-white copyright-item-1'>
                  © {new Date().getFullYear()} Vanderbilt Mortgage and Finance,
                  Inc. All rights reserved
                </p>
                {!hideAdditionalNavItems && (
                  <ul className='legal-footer-list copyright-item-2'>
                    <li className='legal-footer-list-item'>
                      <Link to='/licensing/'>Licensing</Link>
                    </li>
                    <li className='legal-footer-list-item'>
                      <Link to='/foreclosure-prevention/'>
                        Foreclosure Prevention
                      </Link>
                    </li>
                    <li className='legal-footer-list-item'>
                      <Link to='/accessibility/'>Accessibility</Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className='legal-footer-icons'>
              <Picture
                src='https://images.ctfassets.net/y64yks2od8ab/6n5qzS5tzhtADnBX6ADWe2/8e79f02630614d46adab85428dee4410/vanderbuilt-logo.png?w=88'
                alt='Vanderbilt Homes Logo'
              />
              <EqualHousingIcon />
            </div>
          </div>
        </Container>
      </FooterStyles>
    </>
  );
}

Footer.propTypes = {};

export default Footer;
