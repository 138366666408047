import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import AlertStyles from './Alert.styled';
import CloseIcon from '../Shared/CloseIcon';
import ReactMarkdown from 'react-markdown';
import { useAppContext } from '../../contexts/AppContext';
import alertOptions from '../../data/alertOptions.json';
import Modal from '../Shared/Modal';
import theme from '../../styles/theme';

function Alert({
  text,
  linkText,
  linkUrl,
  onClose,
  id,
  background = 'claytonBlue',
  sourcedPage,
  modalHeader,
  modalContent,
  alertOption,
}) {
  const [open, setOpen] = useState(true);
  const [tabIndex, setTabIndex] = useState();
  const [alertHeight, setAlertHeight] = useState('');
  const alertRef = useRef();
  const [{ headerHeight }, dispatch] = useAppContext();
  const [modalOpen, setModalOpen] = useState(false);

  const isModal = useMemo(() => {
    return !!(
      modalHeader &&
      modalContent &&
      alertOption === alertOptions['banner-and-modal'] &&
      linkText
    );
  }, [modalHeader, modalContent, alertOption, linkText]);

  const shouldRenderLink = useMemo(() => {
    return !!(
      linkText &&
      (isModal || alertOption === alertOptions['banner-and-webpage'])
    );
  }, [linkText, isModal, alertOption]);

  const handleClose = e => {
    setOpen(false);
    setTabIndex('-1');
    dispatch({
      type: 'UPDATE_HEADER_HEIGHT',
      headerHeight: headerHeight - alertRef.current.clientHeight,
    });
    setTimeout(() => {
      onClose(e);
    }, 400);
  };

  useEffect(() => {
    setAlertHeight(alertRef.current.scrollHeight);

    window.addEventListener('resize', () => {
      if (alertRef.current) {
        setAlertHeight(alertRef.current.scrollHeight);
      }
    });
  }, []);

  const toggleAlertModal = () => {
    setModalOpen(p => !p);
  };

  return (
    <AlertStyles
      id={id}
      style={{ maxHeight: open ? `${alertHeight}px` : 0 }}
      background={background}
      ref={alertRef}
    >
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <div style={{ paddingTop: '2rem' }}>
            <ReactMarkdown
              source={modalHeader}
              renderers={{
                text: ({ children }) => (
                  <h3
                    style={{
                      paddingLeft: '1.875rem',
                      paddingTop: '0',
                      color: theme.colors.claytonBlue,
                      marginBottom: '0',
                      marginTop: '0',
                    }}
                  >
                    {children}
                  </h3>
                ),
              }}
            ></ReactMarkdown>
          </div>
          <div style={{ padding: '0.5rem 1.875rem 2rem' }}>
            <ReactMarkdown
              source={modalContent}
              renderers={{
                link: ({ href, children }) => (
                  <a
                    tabIndex={tabIndex}
                    target='_blank'
                    href={href}
                    rel='noopener noreferrer'
                  >
                    {children}
                  </a>
                ),
              }}
            />
          </div>
        </Modal>
      )}
      <div className='inner'>
        <div className='text'>
          <ReactMarkdown
            source={text}
            renderers={{
              link: ({ href, children }) => (
                <a
                  tabIndex={tabIndex}
                  target='_blank'
                  href={href}
                  rel='noopener noreferrer'
                >
                  {children}
                </a>
              ),
              paragraph: ({ children }) => (
                <p>
                  {children}{' '}
                  {isModal ? (
                    <button
                      onClick={toggleAlertModal}
                      className='cta cta-btn-link'
                    >
                      {linkText}
                    </button>
                  ) : (
                    shouldRenderLink && (
                      <a
                        tabIndex={tabIndex}
                        target='_blank'
                        href={linkUrl}
                        rel='noopener noreferrer'
                        className='cta'
                      >
                        {linkText}
                      </a>
                    )
                  )}
                </p>
              ),
            }}
          />
        </div>
        <button
          onClick={handleClose}
          tabIndex={tabIndex}
          type='button'
          aria-label='Close'
          className='close-button'
        >
          <CloseIcon />
        </button>
      </div>
    </AlertStyles>
  );
}

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  sourcedPage: PropTypes.bool,
};

export default Alert;
