import React, { useMemo, useState, createRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SubNavStyles from './SubNav.styled';
import { NavLink, useLocation } from 'react-router-dom';
import ChevronDown from '../Shared/ChevronDown';
import { useAppContext } from '../../contexts/AppContext';
import ResizeObserver from 'resize-observer-polyfill';
import theme from '../../styles/theme';
import createGTMClass from '../../util/createGTMClass';

const subNavs = [
  {
    pages: [
      '/calculators/',
      '/calculators/mortgage-calculator/',
      '/calculators/early-payoff-calculator/',
      '/calculators/amortization-calculator/',
      '/calculators/extra-monthly-principal/',
      '/calculators/biweekly-extra-principal/',
    ],
    routes: [
      {
        path: '/calculators/mortgage-calculator/',
        name: 'Mortgage Calculator',
      },
      {
        path: '/calculators/early-payoff-calculator/',
        name: 'Early Payoff Calculator',
      },
      {
        path: '/calculators/amortization-calculator/',
        name: 'Amortization Calculator',
      },
      {
        path: '/calculators/extra-monthly-principal/',
        name: 'Extra Monthly Principal',
      },
      {
        path: '/calculators/biweekly-extra-principal/',
        name: 'Biweekly Extra Principal',
      },
    ],
    mobilePlaceholder: 'All Calculators',
    background: theme.colors.claytonBlue,
  },
  {
    pages: ['/licensing/', '/state-licensing/'],
    routes: [
      {
        path: '/licensing/',
        name: 'Individual Licensing',
      },
      {
        path: '/state-licensing/',
        name: 'State Licensing',
      },
    ],
    mobilePlaceholder: 'All Licenses',
  },
  {
    pages: [
      '/portfolio-home-loan/',
      // '/fha-mortgage/',
      '/land-home-mortgage/',
      '/biweekly-mortgage/',
      '/fresh-start-home-loan/',
      '/direct-lending/',
    ],
    routes: [
      {
        path: '/portfolio-home-loan/',
        name: 'New Manufactured Home Loan',
      },
      // {
      //   path: '/fha-mortgage/',
      //   name: 'FHA Mortgage',
      // },
      {
        path: '/land-home-mortgage/',
        name: 'Land & Home Mortgage',
      },
      {
        path: '/biweekly-mortgage/',
        name: 'Biweekly Payment Loan',
      },
      {
        path: '/fresh-start-home-loan/',
        name: 'Less Than Perfect Credit',
      },
      {
        path: '/direct-lending/',
        name: 'Used Home Loan',
      },
    ],
    mobilePlaceholder: 'All Mortgages',
  },

  {
    pages: [
      '/resources/',
      '/resources/blog/',
      '/resources/blog/*',
      '/resources/documents-forms/',
      '/resources/faqs/',
      '/resources/faqs/*',
    ],
    routes: [
      {
        path: '/resources/blog/',
        name: 'Tips & Tools',
      },
      {
        path: '/resources/documents-forms/',
        name: 'Documents & Forms',
      },
      {
        path: '/resources/faqs/',
        name: 'FAQs',
      },
      {
        path: '/calculators/mortgage-calculator/',
        name: 'Calculators',
      },
    ],
    mobilePlaceholder: 'All Resources',
  },
];

const SubNav = () => {
  const location = useLocation();
  const htmlObserver = useRef(null);
  const activeSubNav = subNavs.find(({ pages }) =>
    pages.reduce((matches, route) => {
      if (matches) {
        return matches;
      }

      if (location.pathname === '/' || location.pathname === '') {
        return matches;
      }

      const isExact = route.indexOf('*') === -1;

      if (isExact) {
        return route.indexOf(location.pathname) > -1;
      } else {
        return location.pathname.indexOf(route.replace('*', '')) > -1;
      }
    }, false)
  );

  const mobilePlaceholder =
    activeSubNav?.mobilePlaceholder || 'Select an option';
  const routes = activeSubNav?.routes || [];
  const background = activeSubNav?.background || theme.colors.claytonBlue;

  const activeRouteName = useMemo(() => {
    const route =
      routes &&
      routes.find(route => location.pathname.indexOf(route.path) > -1);

    return route && route.name ? route.name : mobilePlaceholder;
  }, [routes, location, mobilePlaceholder]);

  const [indicatorStyle, setIndicatorStyle] = useState({
    transition: 'none',
    width: '0px',
    transform: 'translateX(50%)',
    opacity: 0,
  });
  const [subNavHeight, setSubNavHeight] = useState(50);
  const [windowWidth, setWindowWidth] = useState(0);
  const subNavRef = createRef();
  // Manage the nav state
  const [navOpen, setNavOpen] = useState(false);
  const [resizeTimeout, setResizeTimeout] = useState(false);
  const toggleMenu = () => setNavOpen(!navOpen);
  // Use close menu for when user clicks on current route
  const closeMenu = () => setNavOpen(false);
  const [{ scrollDirection, headerHeight }] = useAppContext();

  useEffect(() => {
    htmlObserver.current = new ResizeObserver(entries => {
      setWindowWidth(entries[0].contentRect.width);
    });

    const htmlTag = document.querySelector('html');
    htmlObserver.current.observe(htmlTag);

    return () => {
      if (htmlObserver?.current?.disconnect) {
        htmlObserver.current.disconnect();
      }

      htmlObserver.current = null;
    };
  }, []);

  useEffect(() => {
    if (subNavRef?.current?.clientHeight) {
      setSubNavHeight(subNavRef.current.clientHeight - 1);
    } else {
      setSubNavHeight(50);
    }
  }, [subNavRef.current]);

  useEffect(() => {
    const activeElement = subNavRef?.current?.querySelector('a.active');

    if (activeElement) {
      setIndicatorStyle({
        transition: 'all .4s ease-in-out',
        width: `${activeElement.clientWidth}px`,
        transform: `translateX(${activeElement.offsetLeft - 2}px)`,
        opacity: 1,
      });
    } else {
      setIndicatorStyle({
        transition: 'all .4s ease-in-out',
        width: '0px',
        transform: indicatorStyle.transform,
        opacity: 0,
      });
    }
  }, [location, windowWidth]);

  useEffect(() => {
    const handleWindowResize = e => {
      if (resizeTimeout) {
        setResizeTimeout(clearTimeout(resizeTimeout));
      }

      setResizeTimeout(
        setTimeout(() => {
          setWindowWidth(e.target.outerWidth);
        }, 500)
      );
    };
    window.addEventListener('resize', handleWindowResize);
    setWindowWidth(window.outerWidth);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  if (!activeSubNav) {
    return null;
  }

  return (
    <>
      <SubNavStyles
        ref={subNavRef}
        subNavTop={headerHeight}
        scrollDirection={scrollDirection}
        background={background}
        className='subnav'
      >
        <nav className='subnav-wrapper'>
          <div className='subnav-mobile'>
            <button
              onClick={toggleMenu}
              className={`subnav-mobile-dropdown ${
                navOpen ? 'open' : 'closed'
              }`}
            >
              {activeRouteName ? activeRouteName : <div />}
              <ChevronDown />
            </button>
          </div>
          <ul className={`subnav-list ${navOpen ? 'open' : 'closed'}`}>
            {routes &&
              routes.length &&
              routes.map((route, idx) => (
                <li
                  className='subnav-list-item'
                  key={`subnav__${route.path}__${idx}`}
                >
                  {route.external ? (
                    <a
                      onClick={closeMenu}
                      className={createGTMClass('subnav', 'subnav', 'button', [
                        'subnav-list-link',
                      ])}
                      href={route.path}
                      tabIndex={!navOpen && windowWidth < 992 ? -1 : null}
                    >
                      {route.name}
                    </a>
                  ) : (
                    <NavLink
                      onClick={closeMenu}
                      className={createGTMClass('link', 'subnav', 'button', [
                        'subnav-list-link',
                      ])}
                      to={route.path}
                      tabIndex={!navOpen && windowWidth < 992 ? -1 : null}
                    >
                      {route.name}
                    </NavLink>
                  )}
                </li>
              ))}
          </ul>
          <div className='indicator' style={{ ...indicatorStyle }} />
        </nav>
      </SubNavStyles>
      <div style={{ height: subNavHeight }} />
    </>
  );
};

SubNav.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      external: PropTypes.bool,
    })
  ),
  mobilePlaceholder: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default SubNav;
