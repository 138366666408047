import React, { useEffect } from 'react';
import useWindowScroll from '../hooks/useWindowScroll';
import { useHistory } from 'react-router-dom';
import { getAlerts } from '../services/alertsService';
import isOnline from '../util/isOnline';
import offlinePages from '../data/offlineRoutes';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

export const initialState = {
  menuOpen: false,
  loaded: false,
  alerts: [],
  scrollDirection: 'down',
  lastScroll: 0,
  headerHeight: 0,
  headerContactNumber: '',
  headerLink: '',
  lastRoute: '',
  modal: null,
  windowSize: {
    x: 0,
    y: 0,
  },
};

function appReducer(state, action) {
  if (action.type === 'CLOSE_ALERT') {
    const closedAlertsString = sessionStorage.getItem('vmfAlertsClosed') || '';
    sessionStorage.setItem(
      'vmfAlertsClosed',
      `${closedAlertsString}${closedAlertsString ? ';' : ''}${
        action.payload.id
      }`
    );
  }

  switch (action.type) {
    case 'UPDATE_WINDOW_SIZE':
      return {
        ...state,
        windowSize: action.windowSize,
      };
    case 'TOGGLE_MENU':
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    case 'CLOSE_MENU':
      return {
        ...state,
        menuOpen: false,
      };
    case 'ALERTS_LOADED':
      return {
        ...state,
        alerts: action.payload.alerts || [],
        loaded: true,
      };
    case 'CLOSE_ALERT':
      return {
        ...state,
        alerts: state.alerts.filter(
          alert => alert.pageLink !== action.payload.id
        ),
        headerHeight:
          state.headerHeight -
          (document.getElementById(action.payload.id)?.clientHeight || 0),
      };
    case 'UPDATE_SCROLL_DIR':
      return {
        ...state,
        scrollDirection: action.scrollDirection,
      };
    case 'UPDATE_LAST_SCROLL':
      return {
        ...state,
        lastScroll: action.lastScroll,
      };
    case 'UPDATE_HEADER_HEIGHT':
      return {
        ...state,
        headerHeight: action.headerHeight,
      };
    case 'UPDATE_LAST_ROUTE':
      return {
        ...state,
        lastRoute: action.lastRoute,
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        modal: {
          onClose: action.onClose,
          children: action.children,
          fitContent: action.fitContent,
        },
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modal: null,
      };
    case 'UPDATE_HEADER_CONTACT_NUMBER':
      return {
        ...state,
        headerContactNumber: action.headerContactNumber,
      };
    case 'UPDATE_HEADER_LINK':
      return {
        ...state,
        headerLink: action.headerLink,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const loadAlerts = async dispatch => {
  try {
    const webBanner = await getAlerts();
    const alerts = webBanner.data.result.activeAlerts;
    dispatch({ type: 'ALERTS_LOADED', payload: { alerts } });
  } catch (error) {
    console.error(error);
  }
};

function AppProvider({ children, serverAppState = initialState }) {
  const [state, dispatch] = React.useReducer(appReducer, serverAppState);
  const scrollY = useWindowScroll(10);
  const history = useHistory();

  const updateWindowSize = e => {
    dispatch({
      type: 'UPDATE_WINDOW_SIZE',
      windowSize: {
        x: e.target.innerWidth,
        y: e.target.innerHeight,
      },
    });
  };

  // Update windowSize
  useEffect(() => {
    if (state.loaded) {
      dispatch({
        type: 'UPDATE_WINDOW_SIZE',
        windowSize: {
          x: window.innerWidth,
          y: window.innerHeight,
        },
      });

      window.addEventListener('resize', updateWindowSize);
    }

    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, [state.loaded]);

  // AXE accessibility checker
  if (process.env.NODE_ENV !== 'production') {
    useEffect(() => {
      (async () => {
        const { run } = await import(/* webpackPreload: true */ 'axe-core');

        const checkForViolations = ({ violations }) => {
          violations.forEach(violation => {
            if (
              violation.impact === 'critical' ||
              violation.impact === 'serious'
            ) {
              console.error(violation);
            } else {
              console.warn(violation);
            }
          });
        };

        setTimeout(async () => {
          await run().then(checkForViolations);
        }, 1000);

        history.listen(() => {
          setTimeout(() => {
            run().then(checkForViolations);
          }, 1000);
        });
      })();
    }, []);
  }

  // Update scroll variables on page change
  useEffect(() => {
    isOnline().then(isOnline => {
      if (!isOnline) {
        const isOfflineAccessible = offlinePages.find(
          pathname => location.pathname === pathname
        );

        if (!isOfflineAccessible) {
          history.push('/offline/');
        }
      }
    });

    history.listen(location => {
      dispatch({ type: 'UPDATE_SCROLL_DIR', scrollDirection: 'up' });
      dispatch({ type: 'UPDATE_LAST_SCROLL', lastScroll: 0 });

      isOnline().then(isOnline => {
        if (!isOnline) {
          const isOfflineAccessible = offlinePages.find(
            pathname => location.pathname === pathname
          );

          if (!isOfflineAccessible) {
            history.push('/offline/');
          }
        }
      });
    });
  }, []);

  // Load alerts
  useEffect(() => {
    if (serverAppState && !serverAppState.loaded) {
      loadAlerts(dispatch);
    }
  }, [serverAppState]);

  // Scroll variables on scroll
  useEffect(() => {
    const newDirection =
      state.lastScroll > Math.ceil(scrollY) || scrollY <= state.headerHeight
        ? 'up'
        : 'down';
    dispatch({ type: 'UPDATE_LAST_SCROLL', lastScroll: Math.ceil(scrollY) });

    if (newDirection !== state.direction) {
      dispatch({ type: 'UPDATE_SCROLL_DIR', scrollDirection: newDirection });
    }
  }, [scrollY]);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider');
  }
  return context;
}

function useAppContext() {
  return [useAppState(), useAppDispatch()];
}

function closeAlert(dispatch, id) {
  return dispatch({ type: 'CLOSE_ALERT', payload: { id } });
}

const updateHeaderContactNumber = (dispatch, headerContactNumber) =>
  dispatch({ type: 'UPDATE_HEADER_CONTACT_NUMBER', headerContactNumber });
const updateHeaderLink = (dispatch, headerLink) =>
  dispatch({ type: 'UPDATE_HEADER_LINK', headerLink });

export {
  AppProvider,
  useAppContext,
  closeAlert,
  updateHeaderContactNumber,
  updateHeaderLink,
};
