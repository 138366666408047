import styled from 'styled-components';

const HeaderStyles = styled.header`
  position: relative;
  z-index: 9999;
  position: fixed;
  top: 0;
  transition: all 0.4s ease-in-out;
  width: 100%;
  transform: ${props =>
    props.scrollDirection === 'up' ? '' : 'translateY(-100%)'};

  .inner {
    ${props =>
      props.$ignoreShadow ? '' : 'box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);'}
  }

  > .inner {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    ${props =>
      props.$ignoreShadow
        ? ''
        : `box-shadow: ${
            props.direction === 'up' ? props.theme.shadows.low : ''
          };`}

    background: ${props =>
      props.isDark ? props.theme.colors.vmfBlue : props.theme.colors.white};
    transition: all 0.2s ease-in-out;
  }

  .button-link-vmf-blue {
    color: ${props => props.theme.colors.vmfBlue};
  }

  .mobile_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 10;
    position: relative;

    @media (min-width: ${props =>
        props.overrideBreakpoint || props.theme.breakpoints.desktop}) {
      display: none;
    }

    .mobile_toggle {
      border: none;
      border-left: solid 1px
        ${props =>
          props.isDark
            ? props.theme.colors.darkBlue
            : props.theme.colors.grey5};
      background: transparent;
      border-radius: 0;
      height: 100%;
      padding: 0.25rem 1.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        color: ${props =>
          props.isDark
            ? props.theme.colors.white
            : props.theme.colors.claytonBlue};

        > path {
          transition: all 0.2s ease-in-out;
        }

        .menu_toggle__top {
          transform: ${props =>
            props.isOpen ? 'rotate(45deg) translate(4px, -4px)' : ''};
        }

        .menu_toggle__mid {
          opacity: ${props => (props.isOpen ? 0 : 1)};
        }

        .menu_toggle__bottom {
          transform: ${props =>
            props.isOpen ? 'rotate(-45deg) translate(-10px, -2px)' : ''};
        }
      }
    }
  }

  .desktop_header {
    max-width: ${props => props.theme.maxWidths.header};
    margin: auto;
    display: none;
    width: 100%;
    padding: 0 0.5rem;

    @media (min-width: ${props =>
        props.overrideBreakpoint || props.theme.breakpoints.desktop}) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0.8125rem;
    }
  }

  .mobile_menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: -1;
    transition: all 0.4s ease-in-out;
    padding: 0;
    box-sizing: border-box;
    box-shadow: ${props => props.theme.shadows.low};
    transition: all 0.4s ease-in-out;
    background: ${props => props.theme.colors.white};
    transform: ${props => (props.isOpen ? '' : 'translateY(-100%)')};

    @media (min-width: ${props =>
        props.overrideBreakpoint || props.theme.breakpoints.desktop}) {
      display: none;
    }

    .menus {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: -1;
      transition: all 0.4s ease-in-out;
      padding: 2.0625rem 2.5rem 2.8125rem 2.5rem;
      box-sizing: border-box;
      box-shadow: ${props => props.theme.shadows.low};
      transition: all 0.4s ease-in-out;
      background: ${props => props.theme.colors.white};

      @media (min-width: ${props =>
          props.overrideBreakpoint || props.theme.breakpoints.desktop}) {
        display: none;
      }
    }
  }
`;

export default HeaderStyles;
