import React from 'react';
import PropTypes from 'prop-types';

function VMFLogo({ style = 'color' }) {
  const colors =
    style === 'bw'
      ? ['#93d500', '#05292f']
      : style === 'white'
        ? ['#93D500', '#FFFFFF']
        : ['#93D500', '#0075c9'];

  return (
    <svg
      width='121'
      height='52'
      viewBox='0 0 447.25 141.66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          fill={colors[1]}
          d='m42.05,97.63h-22.54L0,32.22h20.34l10.72,39.67h.18l10.53-39.67h20.7l-20.43,65.41Z'
        />
        <path
          fill={colors[1]}
          d='m103.61,87c0,3.57.18,7.6,2.2,10.63h-18.5c-.46-1.28-.73-3.21-.64-4.49h-.18c-3.85,4.4-9.44,5.68-15.3,5.68-9.16,0-16.76-4.4-16.76-14.38,0-15.02,17.59-14.47,26.84-16.21,2.47-.46,4.95-1.19,4.95-4.21,0-3.21-3.02-4.4-5.95-4.4-5.59,0-6.69,2.84-6.78,4.86h-16.86c.27-6.69,3.57-10.63,8.24-12.83,4.58-2.2,10.63-2.75,16.21-2.75,22.72,0,22.54,9.44,22.54,18.6v19.51Zm-17.41-10.72c-2.2,1.1-4.67,1.74-7.15,2.29-4.03.92-6.14,1.92-6.14,5.13,0,2.2,2.38,4.4,5.68,4.4,4.12,0,7.33-2.47,7.6-7.42v-4.4Z'
        />
        <path
          fill={colors[1]}
          d='m108.65,50.08h17.5v6.05h.18c3.39-4.76,8.24-7.24,15.48-7.24,8.52,0,16.31,5.22,16.31,16.12v32.61h-18.14v-24.92c0-5.5-.64-9.34-5.95-9.34-3.11,0-7.24,1.56-7.24,9.16v25.1h-18.14v-47.54Z'
        />
        <path
          fill={colors[1]}
          d='m212.9,97.63h-17.5v-5.22h-.18c-2.29,3.02-5.04,6.41-13.92,6.41-13.47,0-19.97-13.28-19.97-25.19,0-11.18,4.31-24.73,20.25-24.73,5.41,0,10.26,2.38,13.01,6.41h.18v-23.08h18.14v65.41Zm-25.47-35.54c-6.5,0-7.97,6.14-7.97,11.73s1.47,11.82,7.97,11.82,7.97-6.14,7.97-11.82-1.47-11.73-7.97-11.73Z'
        />
        <path
          fill={colors[1]}
          d='m233.51,77.93c.46,5.41,3.57,9.25,9.25,9.25,2.93,0,6.14-1.1,7.6-3.39h16.95c-3.66,10.26-13.65,15.02-24.46,15.02-15.21,0-26.66-8.98-26.66-24.82,0-13.83,10.08-25.1,25.1-25.1,18.87,0,27.02,10.53,27.02,29.04h-34.81Zm17.4-9.44c0-4.4-3.3-7.97-7.97-7.97-5.41,0-8.52,2.84-9.34,7.97h17.31Z'
        />
        <path
          fill={colors[1]}
          d='m271.34,50.08h17.4v7.6h.18c2.93-5.68,6.78-8.79,13.37-8.79,1.83,0,3.57.27,5.31.73v16.03c-1.83-.64-3.48-1.19-7.15-1.19-7.15,0-10.99,4.21-10.99,14.38v18.78h-18.14v-47.54Z'
        />
        <path
          fill={colors[1]}
          d='m310.88,32.22h18.14v23.08h.18c2.75-4.03,7.6-6.41,13.01-6.41,15.94,0,20.24,13.56,20.24,24.73,0,11.91-6.5,25.19-19.97,25.19-8.89,0-11.63-3.39-13.92-6.41h-.18v5.22h-17.5V32.22Zm25.47,29.86c-6.5,0-7.97,6.14-7.97,11.73s1.47,11.82,7.97,11.82,7.97-6.14,7.97-11.82-1.47-11.73-7.97-11.73Z'
        />
        <path
          fill={colors[1]}
          d='m384.16,44.95h-18.14v-12.73h18.14v12.73Zm-18.14,5.13h18.14v47.54h-18.14v-47.54Z'
        />
        <path fill={colors[1]} d='m389.25,32.22h18.14v65.41h-18.14V32.22Z' />
        <path
          fill={colors[1]}
          d='m437.54,50.08h9.71v11.18h-9.71v16.58c0,4.4,1.01,6.32,5.59,6.32,1.37,0,2.75-.09,4.12-.28v13.74c-3.66,0-7.79.55-11.63.55-7.7,0-16.21-1.19-16.21-14.2v-22.72h-8.06v-11.18h8.06v-14.47h18.14v14.47Z'
        />
      </g>
      <polygon
        fill={colors[0]}
        points='120.92 31.73 202.89 0 284.87 31.73 284.87 37.65 202.89 20.22 120.92 37.65 120.92 31.73 120.92 31.73'
      />
      <g>
        <path
          fill={colors[1]}
          d='m253.46,110.39h5.88v3.07h.09c1.62-2.33,3.99-3.69,6.93-3.69s5.4,1.05,6.62,3.77c1.32-1.97,3.69-3.77,6.84-3.77,4.83,0,8.29,2.24,8.29,8.12v15.18h-6.23v-12.85c0-3.03-.26-5.53-3.82-5.53s-4.17,2.9-4.17,5.75v12.63h-6.23v-12.72c0-2.63.18-5.66-3.77-5.66-1.23,0-4.21.79-4.21,5.22v13.16h-6.23v-22.68Z'
        />
        <path
          fill={colors[1]}
          d='m302.73,109.77c7.15,0,11.76,4.74,11.76,11.98s-4.61,11.93-11.76,11.93-11.71-4.74-11.71-11.93,4.61-11.98,11.71-11.98Zm0,19.21c4.26,0,5.53-3.64,5.53-7.24s-1.27-7.28-5.53-7.28-5.48,3.64-5.48,7.28,1.27,7.24,5.48,7.24Z'
        />
        <path
          fill={colors[1]}
          d='m317.21,110.39h5.92v4.21h.09c1.14-2.85,4.21-4.83,7.24-4.83.44,0,.97.09,1.36.22v5.79c-.57-.13-1.49-.22-2.24-.22-4.56,0-6.14,3.29-6.14,7.28v10.22h-6.23v-22.68Z'
        />
        <path
          fill={colors[1]}
          d='m344.37,110.39h4.56v4.17h-4.56v11.23c0,2.11.53,2.63,2.63,2.63.66,0,1.27-.04,1.93-.18v4.87c-1.05.18-2.41.22-3.64.22-3.82,0-7.15-.88-7.15-5.4v-13.38h-3.77v-4.17h3.77v-6.8h6.23v6.8Z'
        />
        <path
          fill={colors[1]}
          d='m372.4,131.62c0,3.73-1.32,10.05-11.76,10.05-4.47,0-9.7-2.11-10-7.28h6.19c.57,2.33,2.46,3.11,4.65,3.11,3.47,0,5.04-2.37,5-5.62v-2.98h-.09c-1.36,2.37-4.08,3.51-6.8,3.51-6.8,0-9.7-5.18-9.7-11.41,0-5.88,3.38-11.23,9.74-11.23,2.98,0,5.26,1.01,6.76,3.64h.09v-3.03h5.92v21.23Zm-5.92-10.18c0-3.68-1.27-6.98-5.31-6.98-3.51,0-5.04,3.07-5.04,6.45s1.23,6.8,5.04,6.8c3.55,0,5.31-3.03,5.31-6.27Z'
        />
        <path
          fill={colors[1]}
          d='m375.83,117.36c.35-5.83,5.57-7.59,10.66-7.59,4.52,0,9.96,1.01,9.96,6.45v11.8c0,2.06.22,4.12.79,5.04h-6.32c-.22-.7-.39-1.45-.44-2.19-1.97,2.06-4.87,2.81-7.63,2.81-4.3,0-7.72-2.15-7.72-6.8,0-5.13,3.86-6.36,7.72-6.89,3.82-.57,7.37-.44,7.37-2.98,0-2.68-1.84-3.07-4.04-3.07-2.37,0-3.9.97-4.12,3.42h-6.23Zm14.39,4.61c-1.05.92-3.25.96-5.18,1.32-1.93.39-3.69,1.05-3.69,3.33s1.8,2.9,3.82,2.9c4.87,0,5.04-3.86,5.04-5.22v-2.32Z'
        />
        <path
          fill={colors[1]}
          d='m421.76,131.62c0,3.73-1.32,10.05-11.76,10.05-4.47,0-9.7-2.11-10-7.28h6.19c.57,2.33,2.46,3.11,4.65,3.11,3.47,0,5.04-2.37,5-5.62v-2.98h-.09c-1.36,2.37-4.08,3.51-6.8,3.51-6.8,0-9.7-5.18-9.7-11.41,0-5.88,3.38-11.23,9.74-11.23,2.98,0,5.26,1.01,6.76,3.64h.09v-3.03h5.92v21.23Zm-5.92-10.18c0-3.68-1.27-6.98-5.31-6.98-3.51,0-5.04,3.07-5.04,6.45s1.23,6.8,5.04,6.8c3.55,0,5.31-3.03,5.31-6.27Z'
        />
        <path
          fill={colors[1]}
          d='m430.58,123.24c.17,3.95,2.11,5.75,5.57,5.75,2.5,0,4.52-1.54,4.91-2.94h5.48c-1.75,5.35-5.48,7.63-10.62,7.63-7.15,0-11.58-4.91-11.58-11.93s4.69-11.98,11.58-11.98c7.72,0,11.45,6.49,11.01,13.47h-16.36Zm10.13-3.95c-.57-3.16-1.93-4.83-4.96-4.83-3.95,0-5.09,3.07-5.18,4.83h10.13Z'
        />
      </g>
    </svg>
  );
}

VMFLogo.propTypes = {
  style: PropTypes.oneOf(['color', 'bw', 'white']),
};

export default React.memo(VMFLogo);
